import React from 'react'

import Layout from "../../../components/Layout"
import CaseStudyNav from '../../../components/CaseStudyNav'
import CaseStudyStep from '../../../components/CaseStudyStep'

export default class DiagnosticStepFive extends React.Component {

  render() {
    return (
      <div>
        <Layout>
          <CaseStudyNav 
            linkOneClass="aga-casestudy-link"
            linkTwoClass="aga-casestudy-link active"
            linkThreeClass="aga-casestudy-link"
          />         
          <CaseStudyStep
            title="Step #5"
            question="What changes, if any, would you make in Jennifer’s medication at this point? What information should you convey to this patient’s primary care physician?"
            body={[
              <h3>Expert Opinion</h3>,            
              <p>At this point it would make sense to counsel Jennifer about not smoking, limiting NSAID use as this can lead to mucosal damage and a flare of disease, and judicious use of antibiotics. You will also want to help her understand more about the natural history of Crohn’s and her need for continuing assessment. Crohn’s disease is characterized as a progressive inflammation over time, with symptoms that can wax and wane.</p>,
              <p>All patients start out with inflammation and some will quickly progress to penetrating complications while others slowly over time develop fibrostenotic strictures. Risk factors for more aggressive disease progression include a diagnosis under age 30, male sex, small bowel involvement, perianal involvement, smoking, and needing systemic steroids in the first six months of diagnosis.</p>,
              <p>The majority of patients will have progressive disease that will cause anatomic damage and require immunomodulation in the form of an immunomodulator (e.g., a thiopurine) or biologic to control inflammation. Both immunomodulators and biologics decrease the body’s immune response, but immunomodulators decrease immune response generally while biologics target specific proteins involved in the inflammatory process in the gut.</p>, 
              <p>Studies have demonstrated that those patients receiving a biologic earlier in their course of disease have higher response and remission rates than those with disease over five years, likely because some bowel wall damage has already occurred and irreversible. Immunomodulators like thiopurines and methotrexate can result in healing in a proportion of patients, but true mucosal and even histologic healing is achieved with biologics. Thus, Jennifer should be seen and reassessed with blood work including CBC, iron studies, and vitamin and CRP levels in 4-6 months to look for signs of progression. A follow-up MRI in a year to assess for progression and nature of the small bowel is also appropriate.</p>,
              <p>Direct communication with Jennifer’s primary care physician is always most efficient, and can be achieved in a letter, which is also necessary for documentation purposes. The letter should include the diagnosis of Crohn’s disease involving only the distal small bowel (no colon involved) that is currently inflammatory in nature and not associated with any fistula or stricturing that would require an immunomodulatory or biologic agent, or surgery. You should explain that budesonide is being prescribed with the expectation that the pain and diarrhea resolve. </p>,
              <p>In addition, you should recommend that when the primary care physician sees her back, appropriate follow-up for any age-specific vaccinations like influenza, pneumonia, and hepatitis B if negative should be discussed. Any of these should ideally be administered before Jennifer starts any biologic therapy. Since she will be on the equivalent of less than 20 mg of prednisone, she would be eligible for live virus vaccines if appropriate, although any patient using immunomodulator therapy or biologic therapy should avoid using live attenuated vaccines.</p>,
              <p>It would also be reasonable for the primary care physician to assess Jennifer’s vitamin D levels with repletion if levels remain low, and to provide continued counseling about wearing sunscreen to prevent sunburn and subsequent skin cancer. The latter caution will become critical should Jennifer’s disease progress: both melanoma and nonmelanoma skin cancers are more common in patients with IBD, especially in those taking thiopurines and even more so in those taking both thiopurines and anti-TNFs.<sup>9</sup> She will not be so immunocompromised that annual Pap smears would be indicated. </p>,
              <p><sup>9</sup> Bragg JD. Osteopathic Primary Care of Patients with Inflammatory Bowel Disease: A Review. J Am Osteopath Assoc 2014 Sep;114(9):695-701. DOI: <a href="https://doi.org/10.7556/jaoa.2014.139">10.7556/jaoa.2014.139</a></p>
            ]}
            slideLink="#"
            cmeLink="/cme"
            backLink="/steps/step-4"
            backText="Back to Step 4"
            nextLink="/cases/health-maintenance-primary-care"
            nextText="Go to Case Three"
          />
        </Layout>
      </div>
    )
  }
}
